.login-box {
  background: #fff;
}

.table-input {
  width: 65px;
  text-align: center;
}

.table-input:read-only {
  background-color: #dddddd;
  border: 1px solid #525252;
}

.table-input-lg {
  width: 100px;
  text-align: center;
}
.table-input-lg:read-only {
  background-color: #dddddd;
  border: 1px solid #525252;
}

.custom-select {
  z-index: 9999;
}

.cust-cb {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  background-color: #fff;
  cursor: pointer;
}
.cust-cb:checked {
  background-color: #ffffff;
  border: 6px solid #2196f3;
}

.cust-cb-child {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: none;
  background-color: #fff;
  cursor: pointer;
}
.cust-cb-child:checked {
  background-color: #ffffff;
  border: 6px solid #2196f3;
}
